import React, { useRef, createRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import CountUp from 'react-countup'

import Title from './Title'
import Wysiwyg from './Wysiwyg'

// styling
// ==========================================================================================
const Heading = styled(Title)`
  margin-bottom: 8px;
`
const Percent = styled.div`
  font-family: ${({ theme }) => theme.fonts.feature};
  font-size: 60px;
  line-height: 1em;
  color: ${({ theme }) => theme.colors.primary};
  font-variant-numeric: tabular-nums;

  &:after {
    content: '%';
    font-size: 50%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 100px;
  }
`
const ResultHeading = styled.h4`
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`
const ResultsWrapper = styled.div`
  margin: 32px 0;

  p {
    margin-top: 8px;
    margin-bottom: 8px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
`
const IntroRow = styled(Row)`
  margin-bottom: 32px;
`
const ResultRow = styled(Row)`
  margin-bottom: 36px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 140px;
  }
`

// component
// ==========================================================================================
const JoinTeam = ({ resultsTitle, resultsIntro, results }) => {
  if (!results || !results[0] || !results[0].title) {
    return null
  }
  const refs = useRef(results.map(() => createRef(null)))
  const startFunctions = useRef([])
  const options = { threshold: [0.5] }
  const intersectionCallback = useCallback((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const { index } = entry.target.dataset
        startFunctions.current[index].call()
        observer.unobserve(entry.target)
      }
    })
  })
  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback, options)
    refs.current.forEach(({ current }) => {
      observer.observe(current)
    })
    return () => {
      refs.current.forEach(({ current }) => {
        observer.unobserve(current)
      })
    }
  }, [])

  return (
    <section>
      <IntroRow>
        <Col xs={8} xsOffset={1}>
          <Heading tag="h2" content={resultsTitle} />
          <Wysiwyg content={resultsIntro} />
        </Col>
      </IntroRow>
      <ResultsWrapper>
        {results.map(({ title, body, percent }, i) => (
          <ResultRow ref={refs.current[i]} data-index={i} key={title}>
            <Col xs={8} xsOffset={1} md={2} mdOffset={1}>
              <CountUp start={0} end={Number(percent)}>
                {({ countUpRef, start }) => {
                  // add start fn to array that is triggered by observer later
                  startFunctions.current.push(start)
                  return <Percent ref={countUpRef} />
                }}
              </CountUp>
            </Col>
            <Col xs={8} xsOffset={1} md={4} mdOffset={0}>
              <ResultHeading>{title}</ResultHeading>
              <Wysiwyg content={body} />
            </Col>
          </ResultRow>
        ))}
      </ResultsWrapper>
    </section>
  )
}

export default JoinTeam
