import React, { forwardRef } from 'react'
import styled from 'styled-components'

// import { asText } from '../utils/prismic'
import Image from './Image'
import Wysiwyg from './Wysiwyg'
import Title from './Title'
// import downArrow from '../images/down-arrow.svg'

// styles
// ==========================================================================================
const Wrapper = styled.section`
  position: relative;
  margin: 0 0 40px;

  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
  }
`
const Sticky = styled.div`
  position: sticky;
  top: 36px;
`
const Icon = styled.div`
  width: 45px;
  height: 45px;
  margin-bottom: 8px;
  padding: 6px;
  background: ${({ theme }) => theme.colors.gray10};
  border-radius: 6px;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
`
const Heading = styled(Title)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary};
`
const Arrow = styled.a`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 32px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: -8px;
  padding: 16px;
  color: ${({ theme }) => theme.colors.primary};
  background: none;
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    left: 10px;
    bottom: 3px;
    height: 12px;
    width: 12px;
    border: 1px solid;
    border-right: none;
    border-top: none;
    transform: rotate(-45deg);
  }
  &:after {
    content: '';
    position: absolute;
    left: 16px;
    bottom: 1px;
    height: 32px;
    width: 1px;
    border-left: 1px solid;
    transform: scale(1, 1) translate3d(0, 0, 0);
    transition: transform 0.5s ease-in-out;
  }
  @media (pointer: fine) {
    &:hover {
      background: none !important;
      transform: translate3d(0, 16px, 0);

      &:after {
        transform: scale(1, 1.2) translate3d(0, -4px, 0);
      }
    }
  }
`

// component
// ==========================================================================================
const StickySectionItem = forwardRef(
  ({ title, body, icon, dataIndex, nextTitle, formatAnchor }, ref) => {
    const anchor = formatAnchor(title)
    const nextAnchor = formatAnchor(nextTitle)

    return (
      <Wrapper ref={ref} data-index={dataIndex} id={anchor}>
        <Sticky>
          {icon && (
            <Icon>
              <Image image={icon} />
            </Icon>
          )}
          <Heading tag="h4" content={title} />
          <Wysiwyg content={body} />
          {nextAnchor && (
            <Arrow href={`#${nextAnchor}`} title="jump to next section" />
          )}
        </Sticky>
      </Wrapper>
    )
  }
)

export default StickySectionItem
