import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Content from '../components/Content'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Highlight, { isHighlightDataValid } from '../components/Highlight'
import StickySection from '../components/StickySection'
import Results from '../components/Results'
import Separator from '../components/Separator'

export const query = graphql`
  query CaseStudyQuery($uid: String) {
    prismic {
      documents: allCase_studys(uid: $uid) {
        edges {
          node {
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            heroLabel: hero_label
            heroTitle: hero_title
            heroBody: hero_body
            heroImage: hero_image
            heroImageSharp: hero_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            intro
            bodySection: body_section {
              title
              body
              icon
            }
            highlightTitle: highlight_title
            highlightBody: highlight_body
            highlight_image
            highlightImageSharp: highlight_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            highlightCtaLabel: highlight_cta_label
            highlightCta: highlight_cta {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
              ... on PRISMIC__Document {
                meta: _meta {
                  id
                  uid
                  type
                }
              }
            }
            resultsTitle: results_title
            resultsIntro: results_intro
            results {
              title
              body
              percent
            }
          }
        }
      }
    }
  }
`

export default props => {
  const data = get(props, 'data.prismic.documents.edges[0].node')

  return (
    <Layout {...data}>
      <Hero {...data} />
      <Separator />
      <Content>
        <Highlight {...data} />
        {isHighlightDataValid(data) && <Separator />}
        <StickySection {...data} />
        <Separator />
        <Results {...data} />
      </Content>
    </Layout>
  )
}
