import React, {
  // useState,
  // useEffect,
  useCallback,
  useRef,
  createRef,
} from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import { get } from 'lodash'

import { asText } from '../utils/prismic'
// import StickySectionIndex from './StickySectionIndex'
import StickySectionItem from './StickySectionItem'
import Separator from './Separator'

// styles
// ==========================================================================================
const Wrapper = styled.article`
  position: relative;
`

// const StickyCol = styled(Col)`
//   display: none !important;
//   position: sticky;
//   top: 36px;
//   height: 100%;
//   align-self: start;

//   @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
//     display: block !important;
//   }
// `

// const Debug = styled.div`
//   position: fixed;
//   left: 0;
//   top: 0;
//   bottom: 98%;
//   width: 100%;
//   background: yellow;
// `

// component
// ==========================================================================================
const StickySection = ({ bodySection }) => {
  // arbitrary test to see if section has content
  if (!bodySection || !bodySection[0] || !bodySection[0].title) {
    return null
  }
  // const [currentActive, setCurrentActive] = useState(0)
  const refs = useRef(bodySection.map(() => createRef(null)))
  // const options = {
  //   rootMargin: '0px 0px -98%',
  //   threshold: [0, 1],
  // }
  // const intersectionCallback = useCallback(entries => {
  //   entries.forEach(entry => {
  //     if (entry.isIntersecting) {
  //       setCurrentActive(entry.target.dataset.index)
  //     }
  //   })
  // })
  const formatAnchor = useCallback(title => {
    const str = asText(title)
    return str
      ? str
          .trim()
          .toLowerCase()
          .split(' ')
          .join('_')
      : ''
  })

  // useEffect(() => {
  //   const observer = new IntersectionObserver(intersectionCallback, options)
  //   refs.current.forEach(({ current }) => {
  //     observer.observe(current)
  //   })

  //   return () => {
  //     refs.current.forEach(({ current }) => {
  //       observer.unobserve(current)
  //     })
  //   }
  // }, [])

  return (
    <Wrapper>
      {/* <Debug /> */}
      <Row>
        {/* <StickyCol xs={2} xsOffset={1}>
          <StickySectionIndex
            {...{ bodySection, currentActive, formatAnchor }}
          />
        </StickyCol> */}
        <Col xs={6} xsOffset={1} md={6} mdOffset={1}>
          {bodySection.map((section, i) => (
            <StickySectionItem
              {...section}
              ref={refs.current[i]}
              dataIndex={i}
              key={asText(section.title)}
              nextTitle={get(bodySection[i + 1], 'title')}
              formatAnchor={formatAnchor}
            />
          ))}
        </Col>
      </Row>
      <Separator />
    </Wrapper>
  )
}

export default StickySection
